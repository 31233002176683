<template class="NewsListCss">
  <div class="news-info">
    <div class="news-list">
      <div
        class="news-item"
        v-for="item in news"
        :key="item.newsId"
      >
        <div class="body">
          <div class="column newsS">
            <ul>
              <RouterLink :to="`/news/detail/${item.newsId}`">
                <li>
                  <a class="image" href="javascript:;">
                      <img :src="item.newsImage" :alt="item.newsName"/>
                  </a>
                  <div class="info">
                    <p class="name ellipsis-2">
                        {{ item.newsName }}
                      <span>{{item.createTime}}</span>
                    </p>
                    <p class="attr ellipsis-2">
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ item.newsDesc }}
                    </p>
                    <p class="attr-button ellipsis-2">查看详情</p>
                  </div>
                </li>
              </RouterLink>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {orderStatus} from '@/api/constants'
import {usePayTime} from '@/hooks'

export default {
  name: 'newsList',
  props: {
    news: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['on-cancel', 'on-delete', 'on-confirm', 'on-logistics'],
  setup(props) {

  }
}
</script>

<style scoped lang="less">
.news-info {
  min-height: 200px;
  background: #fff;
  display: flex;
}

.news-list {
  width: 100%;
  background-color: #fff;
}

.news-item {
  margin-bottom: 20px;
  border: 3px solid #f5f5f5;

  .body {
    display: flex;
    align-items: stretch;

    .column {
      border-left: 1px solid #f5f5f5;
      text-align: center;
      padding: 20px;

      &.newsS {
        flex: 1;
        padding: 0;
        align-self: center;

        ul {
          li {
            border-bottom: 1px solid #f5f5f5;
            padding: 20px;
            display: flex;

            &:last-child {
              border-bottom: none;
            }

            .image {
              width: 320px;
              height: 180px;
              border: 1px solid #f5f5f5;

              img {
                width: 100%;
                height: 100%;
              }
            }

            .info {
              width: 100%;
              text-align: left;
              padding: 0 20px;
              line-height: 24px;

              p {
                margin-bottom: 5px;

                span{
                  font-weight: normal;
                  font-size: 1.3vh;
                  padding-left: 2vh;
                }

                &.name {
                  height: 50px;
                  font-weight: bold;
                  font-size: 16px;
                  line-height: 25px;
                  padding-left: 5vh;
                }

                &.attr {
                  color: #999;
                  height: 100px;
                  font-size: 14px;

                  span {
                    margin-right: 5px;
                  }
                }

                &.attr-button {
                  color: #999;
                  font-size: 12px;
                  text-align: right;

                  span {
                    margin-right: 5px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
